import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";

import { Header, PostContent, Nav } from "page_components/blog";

const Post = ({ pageContext }) => {
	const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw);

	return (
		<Layout>
			<Header />
			<PostContent data={pageContext?.data} />
			<Nav data={pageContext?.data} />

			{fullSchema && (
				<script type="application/ld+json">
					{JSON.stringify({ ...fullSchema }, null, 2)}
				</script>
			)}
		</Layout>
	);
};

export const Head = ({ pageContext }) => (
	<Seo
		title={
			pageContext?.data?.data?.seo.title
				? pageContext?.data?.data?.seo.title
				: pageContext?.data?.title
		}
		description={
			pageContext?.data?.data?.seo.metaDesc &&
			pageContext?.data?.data?.seo.metaDesc
		}
	/>
);

export default Post;
