import "./styles.scss";

import React from "react";

const Header = () => {
	return (
		<section className="blog-header">
			<div className="blog-header-shape" />
			<div className="container">
				<h1>Aktualności</h1>
			</div>
		</section>
	);
};

export default Header;
