import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import IcoArrow from "icons/post-nav-arrow.svg";

const Nav = ({ data }) => {
	return (
		<section className="post-nav">
			<div className="container">
				<div className="post-nav__wrapper">
					<div>
						{data?.prev && (
							<Link to={`/aktualnosci${data?.prev?.uri}`}>
								<IcoArrow />
								Zobacz poprzedni wpis
							</Link>
						)}
					</div>
					<div>
						{data?.next && (
							<Link to={`/aktualnosci${data?.next?.uri}`}>
								Zobacz następny wpis <IcoArrow />
							</Link>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Nav;
