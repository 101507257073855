import "./styles.scss";

import React, { useState } from "react";

import Button from "components/Button";

const Content = ({ data, categories }) => {
	const [cat, setCat] = useState("aktualnosci");
	const filtered_data = data?.filter((item) =>
		item.categories.nodes.find((el) => el.slug === cat)
	);

	return (
		<section className="blog-content">
			<div className="blog-content__categories">
				{categories
					?.filter((item) => item?.slug !== "uncategorized")
					?.map((item, index) => (
						<button
							key={index}
							onClick={() => setCat(item?.slug)}
							className={`custom-button${
								item?.slug === cat ? " current" : ""
							}`}
						>
							{item?.name}
						</button>
					))}
			</div>

			{filtered_data.length > 0 ? (
				filtered_data?.map((item, index) => (
					<div className="blog-content__post" key={index}>
						<div className="blog-content__post-wrapper">
							<div
								className="blog-content__post-image"
								style={{
									backgroundImage: `url('${item?.featuredImage?.node?.localFile?.publicURL}')`,
								}}
							/>
							<div>
								<p className="blog-content__post-date">
									{item?.date
										.split("T")[0]
										.split("-")
										.reverse()
										.join(".")}
								</p>
								<h3>{item.title}</h3>
								{index === 0 && (
									<div
										className="blog-content__post-excerpt"
										dangerouslySetInnerHTML={{
											__html: item.excerpt,
										}}
									/>
								)}
								<Button href={`/aktualnosci${item.uri}`}>
									Dowiedz się więcej
								</Button>
							</div>
						</div>
					</div>
				))
			) : (
				<p className="blog-content__info">
					Brak postów do wyświetlenia.
				</p>
			)}
		</section>
	);
};

export default Content;
