import "./styles.scss";

import React, { useState, useRef } from "react";
import Lightbox from "react-image-lightbox";
import Slider from "react-slick";

import ArrowButton from "components/ArrowButton";

const PostContent = ({ data }) => {
	const gallery = data.data.acfSinglePost.postGallery;

	const slider = useRef(null);
	const [selectedPhoto, setSelectedPhoto] = useState(null);

	const sliderSettings = {
		arrows: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
	};

	return (
		<section className="post-content">
			<div className="container">
				<div className="post-content__wrapper">
					<p className="post-content__date">
						{data?.data?.date.split("T")[0].split("-").reverse().join(".")}
					</p>
					<h1 className="post-content__title">{data?.data?.title}</h1>
					{data?.data?.featuredImage?.node?.localFile?.publicURL && (
						<div className="post-content__thumbnail">
							<img
								src={data?.data?.featuredImage?.node?.localFile?.publicURL}
								alt={data?.data?.title}
							/>
						</div>
					)}
					<div
						className="post-content__content"
						dangerouslySetInnerHTML={{
							__html: data?.data?.content,
						}}
					/>
					{gallery?.length > 0 && (
						<div className="post-content__gallery">
							<h3>Galeria</h3>
							<div className="post-content__gallery-row">
								{gallery.map((image, index) => (
									<button
										key={index}
										className="post-content__gallery-item"
										onClick={() => setSelectedPhoto(index)}
									>
										<img src={image.sourceUrl} alt="" />
										Zdjęcie {index + 1}
									</button>
								))}
							</div>
							<div className="post-content__gallery-slider">
								<Slider ref={slider} {...sliderSettings}>
									{gallery.map((image, index) => (
										<div key={index}>
											<button
												className="post-content__gallery-item"
												onClick={() => setSelectedPhoto(index)}
											>
												<img src={image.sourceUrl} alt="" />
												Zdjęcie {index + 1}
											</button>
										</div>
									))}
								</Slider>
								<div className="post-content__gallery-slider-navigation">
									<ArrowButton onClick={() => slider?.current?.slickPrev()} />
									<ArrowButton onClick={() => slider?.current?.slickNext()} />
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

			{selectedPhoto !== null && (
				<Lightbox
					mainSrc={gallery[selectedPhoto]?.sourceUrl}
					nextSrc={gallery[(selectedPhoto + 1) % gallery.length]?.sourceUrl}
					prevSrc={
						gallery[(selectedPhoto + gallery.length - 1) % gallery.length]
							?.sourceUrl
					}
					onCloseRequest={() => setSelectedPhoto(null)}
					onMovePrevRequest={() =>
						setSelectedPhoto(
							(selectedPhoto + gallery.length - 1) % gallery.length
						)
					}
					onMoveNextRequest={() =>
						setSelectedPhoto((selectedPhoto + 1) % gallery.length)
					}
				/>
			)}
		</section>
	);
};

export default PostContent;
